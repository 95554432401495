var exports = {};

var __spreadArray = exports && exports.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
var actionTypes = {
  equals: "",
  element: "~",
  start: "^",
  end: "$",
  any: "*",
  not: "!",
  hyphen: "|"
};
var charsToEscape = new Set(__spreadArray(__spreadArray([], Object.keys(actionTypes).map(function (typeKey) {
  return actionTypes[typeKey];
}).filter(Boolean), true), [":", "[", "]", " ", "\\", "(", ")", "'"], false));
/**
 * Turns `selector` back into a string.
 *
 * @param selector Selector to stringify.
 */

function stringify(selector) {
  return selector.map(stringifySubselector).join(", ");
}

exports.default = stringify;

function stringifySubselector(token) {
  return token.map(stringifyToken).join("");
}

function stringifyToken(token) {
  switch (token.type) {
    // Simple types
    case "child":
      return " > ";

    case "parent":
      return " < ";

    case "sibling":
      return " ~ ";

    case "adjacent":
      return " + ";

    case "descendant":
      return " ";

    case "universal":
      return getNamespace(token.namespace) + "*";

    case "tag":
      return getNamespacedName(token);

    case "pseudo-element":
      return "::" + escapeName(token.name);

    case "pseudo":
      if (token.data === null) return ":" + escapeName(token.name);

      if (typeof token.data === "string") {
        return ":" + escapeName(token.name) + "(" + escapeName(token.data) + ")";
      }

      return ":" + escapeName(token.name) + "(" + stringify(token.data) + ")";

    case "attribute":
      {
        if (token.name === "id" && token.action === "equals" && !token.ignoreCase && !token.namespace) {
          return "#" + escapeName(token.value);
        }

        if (token.name === "class" && token.action === "element" && !token.ignoreCase && !token.namespace) {
          return "." + escapeName(token.value);
        }

        var name_1 = getNamespacedName(token);

        if (token.action === "exists") {
          return "[" + name_1 + "]";
        }

        return "[" + name_1 + actionTypes[token.action] + "='" + escapeName(token.value) + "'" + (token.ignoreCase ? "i" : token.ignoreCase === false ? "s" : "") + "]";
      }
  }
}

function getNamespacedName(token) {
  return "" + getNamespace(token.namespace) + escapeName(token.name);
}

function getNamespace(namespace) {
  return namespace !== null ? (namespace === "*" ? "*" : escapeName(namespace)) + "|" : "";
}

function escapeName(str) {
  return str.split("").map(function (c) {
    return charsToEscape.has(c) ? "\\" + c : c;
  }).join("");
}

export default exports;